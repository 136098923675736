<script setup lang="ts">
import VStack from "@magnit/core/src/components/VStack/VStack.vue";
import VStackItem from "@magnit/core/src/components/VStack/VStackItem.vue";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import yaBottomSlotConfig from "~/ya-slots/appInfo";

const slotApi = useSlotsStore();
const { send } = useAnalytics();
const { adfoxOwnerId } = usePublicConfig();
const stub = ref(false);
</script>

<template>
  <VStack v-if="!stub">
    <VStackItem>
      <div class="product-bottom-slot">
        <VYaSlot
          :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
          :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
          :owner-id="adfoxOwnerId"
          :config="yaBottomSlotConfig"
          :analytics-sender="send"
          :analytics-view="{
            name: 'CardDetail:AdFox:View',
          }"
          :analytics-click="{
            name: 'CardDetail:AdFox:Click',
          }"
          with-hover
          @stub="stub = true"
        />
      </div>
    </VStackItem>
  </VStack>
</template>

<style scoped lang="postcss">
.product-bottom-slot {
  margin-top: var(--pl-unit-x10);
  padding-bottom: var(--pl-unit-x5);

  @media (--pl-viewport-from-m) {
    margin-top: 48px;
    padding-bottom: var(--pl-unit-x6);
  }

  @media (--pl-viewport-from-ml) {
    margin-top: 64px;
    padding-bottom: var(--pl-unit-x8);
  }

  @media (--pl-viewport-from-l) {
    margin-top: 104px;
  }

  &:deep(a) {
    border-radius: 16px;
    overflow: hidden;

    img {
      height: 88px !important;
      object-fit: cover;
    }
  }
}

@media (--pl-viewport-from-m) {
  .product-bottom-slot {
    &:deep(a) {
      border-radius: 20px;

      img {
        height: 128px !important;
      }
    }
  }
}

@media (--pl-viewport-from-l) {
  .product-bottom-slot {
    &:deep(a) {
      border-radius: 24px;
    }
  }
}
</style>
